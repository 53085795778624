<template>
    <div class="d-flex flex-column vh-100">
        <div v-if="paymentStatus==null">
            <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                <div class="mb-5">
                    <i class="ri-refresh-line ri-6x text-info"></i>
                </div>
                <h4>{{ $t('processing') }}</h4>
            </div>
        </div>
        <div v-if="paymentStatus=='approved'">
            <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                <div class="mb-5">
                    <i class="ri-checkbox-circle-line ri-6x text-success"></i>
                </div>
                <h4>{{ $t('your_payment_transaction_has_been_successfully_completed') }}</h4>
            </div>
        </div>
        <div v-if="paymentStatus=='error'">
            <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                <div class="mb-5">
                    <i class="ri-close-circle-line ri-6x text-danger"></i>
                </div>
                <h4>
                    {{ $t('something_went_wrong') }}<br>{{ $t('please_try_again') }}<br><br>
                    <small v-if="paymentStatusMessage">({{ paymentStatusMessage }})</small>
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
    import EPaymentService from "@/services/EPaymentService"

    export default {
        metaInfo() {
            return {
                title: this.$t('status') + ' | ' + this.$t('epayment')
            }
        },
        data() {
            return {
                epaymentToken: null,
                paymentOrderId: null,
                paymentStatus: null,
                paymentStatusMessage: null,
            }
        },
        beforeCreate() {
            this.$store.dispatch('epayment/initAuth')
                .then(() => {
                    this.epaymentToken = this.$store.getters['epayment/getEpaymentToken'];
                    this.paymentOrderId = this.$route.params.paymentOrderId;
                })
                .then(() => {
                    if (this.paymentOrderId) {
                        this.getPaymentStatus();
                    }
                    else {
                        this.$router.push('/404');
                    }
                })
        },
        methods: {
            getPaymentStatus() {
                return EPaymentService.paymentStatus(this.epaymentToken, this.paymentOrderId)
                                      .then(response => {
                                          this.paymentStatus = response.data.data.status;
                                          this.paymentStatusMessage = response.data.data.message;
                                          if (this.paymentStatus != 'approved') {
                                              this.paymentStatus = 'error';
                                          }
                                          else {
                                              if(response.data.data.redirect_url){
                                                  setTimeout(()=>{
                                                      window.top.location.href=response.data.data.redirect_url;
                                                  }, 2000);
                                              }
                                          }
                                      })
                                      .catch(error => {
                                          this.paymentStatus = 'error';
                                      });
            },
        }
    }
</script>
